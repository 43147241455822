import './contentgrid.css'

import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { isNullOrEmpty } from '../helpers/ObjectHelpers';
import Icons from './icons/Icons';
import CopyToClipboard from './CopyToClipboard';
import { IconColors } from '../../services/SystemNames';
import { HandWaving } from 'phosphor-react-sc';
import { Lighthouse } from '@phosphor-icons/react/dist/ssr';

export default function ContentGrid(
    {
        children,
        title = "",
        titleIcon = "",
        subTitle = "",
        defaultContentClassName = "",
        className = "",
        canPullToRefresh = false,
        onPullToRefresh,
        canCopySubTitle = false,
        autoHideTitle = true,
        panel = null,
        addContentSpace = true
    }
) {

    const [warning, setWarning] = useState("");
    const [hasPulled, setHasPulled] = useState(false);

    useEffect(() => {
        window.addEventListener("offline", handleOnOffline);
        window.addEventListener("online", handleOnOffline);

        handleOnOffline();

        return function cleanupEventListeners() {
            window.removeEventListener("offline", handleOnOffline);
            window.removeEventListener("online", handleOnOffline);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleRefresh = () => {

        if (hasPulled) return;

        setHasPulled(true);

        return new Promise(res => {
            setTimeout(() => {
                res(onPullToRefresh());
                setHasPulled(false);
            }, 250);
        });

    };

    return (
        <div className={`content-grid ${className}`}>
            {
                !isNullOrEmpty(warning) &&
                <div className="content-warning">{parse(warning)}</div>
            }
            <ContentGridTitle
                title={title}
                subTitle={subTitle}
                icon={titleIcon}
                autoHide={autoHideTitle}
                canCopySubTitle={canCopySubTitle}
            />
            {
                !isNullOrEmpty(panel) &&
                    <div className="content-grid-panel">
                        {panel}
                    </div>
            }
            <div className={`default-content ${defaultContentClassName} ${autoHideTitle ? addContentSpace ? 'default-content-space' : '' : ''}`}>
                <PullToRefresh onRefresh={handleRefresh} isPullable={canPullToRefresh}
                    pullingContent={<div className="ptr-content">&darr;  Dra ned for &aring; laste inn p&aring; ny &darr;</div>}>
                    <div className="default-content-grid">
                        <div>
                            {children}
                        </div>
                    </div>
                </PullToRefresh>
            </div>
        </div>
    );

    function handleOnOffline() {
        setWarning(navigator.onLine ? "" : "Du er ikke p&aring; nett og lagring skjer kun lokalt");
    }
}

export const ContentGridTitle = ({ title, subTitle, icon, canCopySubTitle, autoHide = false }) => {

    return (
        (!isNullOrEmpty(title)) &&
        <div className={`content-grid-title ${autoHide ? 'content-grid-title-hide' : ''}`} >
            <div className="content-grid-title-text k-d-grid grid-cols-auto-fr k-gap-2">
                <div>
                    {(() => {
                        switch (icon) {
                            case "handWaving":
                                return (<HandWaving size={getDimensions()} />)
                            case "lighthouse":
                                return (<Lighthouse size={getDimensions()} />)
                            default:
                                return (<Icons iconName={icon} color={IconColors.Primary} dimensions={getDimensions()} />);
                        }
                    })()}
                </div>
                <div>
                    <h1>{title}</h1>
                    {
                        (!isNullOrEmpty(subTitle)) &&
                        <h2>
                            {
                                canCopySubTitle ?
                                    <CopyToClipboard text={subTitle} />
                                    :
                                    parse(subTitle)
                            }
                        </h2>
                    }
                </div>
            </div>
        </div>
    )

    function getDimensions() {
        if (isNullOrEmpty(subTitle)) return 45;
        return 70;
    }
}
